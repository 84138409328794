import { ReactNode } from 'react';

import { Col, Row, Select } from 'antd';

import { MFormItemError } from '../MFormItemError/MFormItemError';

import * as Styles from './MSelect.styles';

const { Option } = Select;

export interface MSelectProps {
  value?: string;
  options: { value: string; label: string | ReactNode }[];
  defaultValue?: string;
  onChange: (value: string) => void;
  error?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  testId?: string;
}

export const MSelect = ({
  value,
  options,
  defaultValue,
  onChange,
  error,
  placeholder,
  disabled,
  className = ' ',
  testId,
}: MSelectProps) => {
  return (
    <Row className={className ?? ''}>
      <Col span={24}>
        <Select
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          placeholder={placeholder ?? 'Select'}
          bordered={false}
          disabled={disabled}
          className={Styles.select.concat(' ').concat(className).trimEnd()}
          data-testid={testId + '-' + 'value'}>
          {options.map(option => (
            <Option key={`menu_item_${option.value}`} value={option.value} label={option.label}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Col>
      <Col span={24}>
        <MFormItemError value={error} data-testid={testId + '-' + 'error'} />
      </Col>
    </Row>
  );
};
