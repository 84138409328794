import { Col } from 'antd';
import { IdentityVerificationInstructions, MFormDateField, MFormTextField, MTooltip } from 'src/lib';
import { Account } from 'src/models';
import { Font, Spacing } from 'src/styles';

import { SocialSecurityNumberTooltipContent } from './SocialSecurityNumberTooltipContent';

export interface AccountHolderPersonalInformationViewProps {
  account?: Account;
}

export const AccountHolderPersonalInformationView = ({ account }: AccountHolderPersonalInformationViewProps) => {
  const formatSsid = () => {
    const ssid = account?.primaryAccountHolder?.maskedSsid;

    if (ssid) {
      return ssid.substring(0, 3) + ' - ' + ssid.substring(3, 5) + ' - ' + ssid.substring(5, 9);
    }

    return ssid;
  };

  return (
    <>
      <Col span={24} className={Spacing.mb12}>
        <MTooltip
          icon='info'
          title='Identity Verification Instructions'
          label='Please enter your name as shown on a goverment-issued ID'
          type='modal'
          className={Font.md}>
          <IdentityVerificationInstructions />
        </MTooltip>
      </Col>
      <MFormTextField
        label='First Name'
        value={account?.primaryAccountHolder?.firstName}
        testId={'account-first-name'}
      />
      <MFormTextField
        label='Middle Initial or Middle Name'
        value={account?.primaryAccountHolder?.middleName}
        testId={'account-middle-name'}
      />
      <MFormTextField label='Last Name' value={account?.primaryAccountHolder?.lastName} testId={'account-last-name'} />
      <MFormTextField label='Suffix' value={account?.primaryAccountHolder?.suffix} testId={'account-suffix'} />
      <MFormTextField
        label='Social Security Number'
        value={formatSsid()}
        tooltip={{
          type: 'modal',
          title: 'Why do we need this?',
          content: <SocialSecurityNumberTooltipContent />,
        }}
        testId={'account-ssn'}
      />
      <MFormDateField label='Date of Birth' value={account?.primaryAccountHolder?.dateOfBirth} testId={'account-dob'} />
      <MFormTextField
        label='Marital Status'
        value={account?.primaryAccountHolder?.maritalStatus?.label}
        testId={'account-marital-status'}
      />
      <MFormTextField
        label='Number of Dependents'
        value={account?.primaryAccountHolder?.numberOfDependents}
        testId={'account-number-of-dependents'}
      />
    </>
  );
};
