import { useMemo } from 'react';

import { Col, Row } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { US_PHONE_NUMBER_PREFIX } from 'src/constants';
import { countryCodes } from 'src/constants/countryCodes';
import { AccountHolderPhoneNumberTypeDto } from 'src/dtos';
import { ScreenBreakpoint } from 'src/styles';
import { mask, unmask } from 'src/utils';

import { CountryFlag } from '../CountryFlag/CountryFlag';
import { MInput } from '../MInput/MInput';
import { MSelect } from '../MSelect/MSelect';

import * as Styles from './PhoneNumberInput.styles';

export interface PhoneNumberOption {
  prefix: string;
  value: string;
  type: string;
}

export interface PhoneNumberInputProps {
  placeholder?: string;
  defaultValue?: PhoneNumberOption;
  options: PhoneNumberOption[];
  onChange?: (value: PhoneNumberOption[]) => void;
  disabled?: boolean;
  error?: string | PhoneNumberOption[];
  align?: 'horizontal' | 'vertical';
  international?: boolean;
  multiple?: boolean;
  autoComplete?: string;
  testId?: string;
}

const PHONE_NUMBER_TYPE_OPTIONS = [
  {
    value: AccountHolderPhoneNumberTypeDto.Mobile,
    label: 'Mobile',
  },
  {
    value: AccountHolderPhoneNumberTypeDto.Work,
    label: 'Work',
  },
  {
    value: AccountHolderPhoneNumberTypeDto.Home,
    label: 'Home',
  },
];
const COUNTRY_CODE_OPTION_LIST = countryCodes.map(country => ({
  label: <CountryFlag name={country.code} dialCode={country.dialCode} flag={country.flag} />,
  value: country.dialCode,
}));

export const PhoneNumberInput = ({
  placeholder = 'Enter',
  onChange,
  options,
  defaultValue,
  disabled,
  align = 'horizontal',
  international,
  multiple = true,
  error,
  autoComplete,
  testId,
}: PhoneNumberInputProps) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  const findPhoneMask = useMemo(
    () => (prefix: string) => {
      return countryCodes.find(aCountry => aCountry.dialCode === prefix)?.phoneMask ?? '(999) 999-9999';
    },
    [],
  );

  const canAddPhoneNumber = () => multiple && options.length < 3;

  const findUnusedType = () => {
    const usedTypes = options.map(option => option.type);

    return PHONE_NUMBER_TYPE_OPTIONS.find(option => !usedTypes.includes(option.value));
  };

  const findUnusedTypeOptions = () => {
    return PHONE_NUMBER_TYPE_OPTIONS.filter(option => !options.map(option => option.type).includes(option.value));
  };

  const _onChange = (value: string, _index: number) => {
    if (onChange) {
      onChange(
        options.map((anOption, index) => {
          if (_index === index) {
            return {
              ...anOption,
              value: unmask({
                value,
                mask: findPhoneMask(options[index].prefix),
                substitute: '9',
                separators: ['-', '(', ')'],
              }),
            };
          }

          return anOption;
        }),
      );
    }
  };

  const _onPrefixChange = (prefix: string, _index: number) => {
    if (onChange) {
      onChange(
        options.map((anOption, index) => {
          if (_index === index) {
            return { ...anOption, prefix };
          }

          return anOption;
        }),
      );
    }
  };

  const _onTypeChange = (type: string, _index: number) => {
    if (onChange) {
      onChange(
        options.map((anOption, index) => {
          if (_index === index) {
            return { ...anOption, type };
          }

          return anOption;
        }),
      );
    }
  };

  const renderOptionList = () => {
    return options.map((anOption, index) => (
      <Row key={anOption.type} className={Styles.option}>
        <Col span={options.length > 1 && multiple ? 23 : 24}>
          <MInput
            placeholder={placeholder}
            onChange={value => {
              _onChange(value, index);
            }}
            value={mask({
              value: anOption.value,
              mask: findPhoneMask(options[index].prefix),
              substitute: '9',
            })}
            defaultValue={defaultValue?.value}
            disabled={disabled}
            className={Styles.input}
            addOnBefore={
              <MSelect
                value={anOption.prefix}
                options={
                  international
                    ? COUNTRY_CODE_OPTION_LIST
                    : [
                        {
                          value: US_PHONE_NUMBER_PREFIX,
                          label: (
                            <CountryFlag
                              name={countryCodes[0]?.name}
                              dialCode={US_PHONE_NUMBER_PREFIX}
                              flag={countryCodes[0]?.flag}
                            />
                          ),
                        },
                      ]
                }
                onChange={value => _onPrefixChange(value, index)}
                defaultValue={defaultValue?.prefix}
                className={Styles.beforeInput}
                testId={testId}
              />
            }
            addOnAfter={
              multiple ? (
                <MSelect
                  value={anOption.type}
                  options={findUnusedTypeOptions()}
                  onChange={value => _onTypeChange(value, index)}
                  defaultValue={defaultValue?.type}
                  className={Styles.afterInput}
                />
              ) : undefined
            }
            error={Array.isArray(error) ? error[index]?.value : error}
            autoComplete={autoComplete}
            testId={testId + '-' + 'input'}
          />
        </Col>
        {options.length > 1 && index !== 0 && (
          <Col
            span={1}
            onClick={() => {
              if (onChange) {
                onChange(options.filter((_, i) => i !== index));
              }
            }}
            className={Styles.removePhoneNumberText}>
            <i className='ri-indeterminate-circle-fill' />
          </Col>
        )}
      </Row>
    ));
  };

  return (
    <Col span={isMobile ? 24 : align === 'horizontal' && multiple ? 17 : 24} className={Styles.col}>
      {renderOptionList()}
      {canAddPhoneNumber() && (
        <Row justify={'end'}>
          <div
            onClick={() => {
              if (onChange) {
                onChange([
                  ...options,
                  {
                    type: findUnusedType()?.value ?? AccountHolderPhoneNumberTypeDto.Mobile,
                    value: '',
                    prefix: US_PHONE_NUMBER_PREFIX,
                  },
                ]);
              }
            }}
            className={Styles.addPhoneNumberText}>
            <i className='ri-add-circle-fill' />
            <span>Add Phone Number</span>
          </div>
        </Row>
      )}
    </Col>
  );
};
